<template>
  <div id="app">
    <router-view />
    <service  />

  </div>
</template>

<script>

import service from "@/components/service.vue";
export default {
  name: 'App',
  data() {
    return {


    };
  },
  methods: {

  },
  components: {
    service
  }

}
</script>

<style>
#app {
  font-family: PingFang SC, Helvetica, Arial, sans-serif;
  position: relative;
  z-index: 222;

}



.video-js .vjs-fullscreen-control,
.video-js .vjs-mute-control {
  display: none !important;
}

.video-js .vjs-play-control,
.video-js .vjs-picture-in-picture-control {
  display: none;

}

.video-js .vjs-volume-panel {
  width: 0px !important;
}

.video-js .vjs-control-bar {
  background: #000 !important;
}

.video-js .vjs-big-play-button {
  display: none !important;
}

.video {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  z-index: 1000;
}
</style>
