import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import './assets/font/iconfont.css'  

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
import videojs from "video.js";

import "video.js/dist/video-js.css";

Vue.prototype.$video = videojs;

import ElementUI from 'element-ui';
Vue.use(ElementUI);
import { Toast,Dialog } from 'vant';
Vue.use(Toast);
Vue.use(Dialog);
Vue.prototype.$Toast = Toast;
Vue.prototype.$Dialog = Dialog;
import 'element-ui/lib/theme-chalk/index.css'

import waterfall from "vue-waterfall2";
Vue.use(waterfall);
import 'amfe-flexible';
 import './assets/css/public.css'
import router from './router' //1.引入


import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)


Vue.prototype.tel = function(){
  window.location.href='tel:(010)84934479';
}
//验证手机号
Vue.prototype.$isValidPhoneNumber=function(phoneNumber) {
  return /^1[3-9]\d{9}$/.test(phoneNumber); // 中国大陆手机号码正则表达式
},
Vue.prototype.$updateMeta = function(name, content) {
  const meta = document.querySelector('meta[name="' + name + '"]');
  if (meta) {
    meta.setAttribute('content', content);
  } else {
    const newMeta = document.createElement('meta');
    newMeta.setAttribute('name', name);
    newMeta.setAttribute('content', content);
    document.head.appendChild(newMeta);
  }
}

// const baseSize = 75 // 注意此值要与 postcss.config.js 文件中的 rootValue保持一致
// // 设置 rem 函数
// Vue.prototype.setRem = function() {
//   // 当前页面宽度相对于 375宽的缩放比例，可根据自己需要修改,一般设计稿都是宽750(图方便可以拿到设计图后改过来)。
//   const scale = document.documentElement.clientWidth / 750
//   // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
//   document.documentElement.style.fontSize = baseSize * Math.min(scale, 1) + 'px'
// }
// window.onpageshow = function() {
//   Vue.prototype.setRem()//在重新进入页面的时候重新设置rem
// }

new Vue({
  data: {
    serviceDialog: false // 默认组件是可见的
  },
  router,
  render: h => h(App),
}).$mount('#app')

