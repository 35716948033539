import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};





const routes = [
    {
        path: '/',
        redirect: '/index'
    },
    {
        path:'/caseDetail/:id',
        name:'caseDetail',
        component:() => import('@/views/caseDetail'),
        meta:{
            title: '首页',
            keywords: '家, 居, 家居',
            description: '关于家居的描述。'
        }
    },
    {
        path:'/designer',
        name:'designer',
        component:() => import('@/views/designer'),
    },
    {
        path:'/index',
        name:'index',
        component:() => import('@/views/index'),
    },
    // {
    //     path:'/myDemo',
    //     name:'myDemo',
    //     component:() => import('@/views/myDemo'),
    // },
    {
        path:'/search',
        name:'search',
        component:() => import('@/views/search'),
    },
    {
        path:'/searchDetail',
        name:'searchDetail',
        component:() => import('@/views/search-detail'),
    },
    {
        path:'/case', 
        name:'case',
        component:() => import('@/views/case'),
    },
   
  
    {
        path:'/designerDetail/:id',
        name:'designerDetail',
        component:() => import('@/views/designerDetail'),
    },
    {
        path:'/offer',
        name:'offer',
        component:() => import('@/views/offer'),
    },
    {
        path:'/offerTemplate',
        name:'offerTemplate',
        component:() => import('@/views/offerTemplate'),
    },
    {
        path:'/renovation',
        name:'renovation',
        component:() => import('@/views/renovation'),
    },
    {
        path:'/productList',
        name:'productList',
        component:() => import('@/views/productList'),
       
    },
    {
        path:'/productDetailV3',
        name:'productDetailV3',
        component:() => import('@/views/productDetailV3'),
    },
    {
        path:'/productDetailV1',
        name:'productDetailV1',
        component:() => import('@/views/productDetailV1'),
    },
    {
        path:'/productDetailV5',
        name:'productDetailV5',
        component:() => import('@/views/productDetailV5'),
    },
    {
        path:'/productDetailS5',
        name:'productDetailS5',
        component:() => import('@/views/productDetailS5'),
    },
    {
        path:'/productDetailS7bs',
        name:'productDetailS7bs',
        component:() => import('@/views/productDetailS7bs'),
    },
    {
        path:'/productDetailS7',
        name:'productDetailS7',
        component:() => import('@/views/productDetailS7'),
    },
    {
        path:'/ljyzf',
        name:'ljyzf',
        component:() => import('@/views/ljyzf'),
    },
   
    

]  


export default new VueRouter({
  // base: './', // 根据服务器配置的静态资源目录进行调整
    mode:'history',
   // base: process.env.BASE_URL,
    routes
})