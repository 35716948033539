<template>
  <div class="projectService" >
    <div class="doyoo_panel_mobile" @click="open"></div>
    <van-popup v-model="serviceDialog" position="bottom" :style="{ height: '60%' }" @click-overlay="close">
      <div class="doyoo_f_work_wrapper">
        <div id="doyoo_f_head" style="height:45px;" @click="close">
          <a id="doyoo_f_phone_btn"
            style="width: 40px;height: 40px;display: block;float: right;margin-right: 50px;position: relative;z-index: 2;"
            @click.stop="tel"></a>
        </div>
        <iframe frameborder="0" id="doyoo_f_work" width="100%" height="100%"
          style="position: absolute;left:0;right:0;bottom:0;top:0;"
          src="https://chat6842.talk99.cn/chat/chat/p.do?g=10064064&amp;md=5&amp;c=20001829&amp;v=e405266a13615643966b3e0d8918e9bc&amp;u=a7882be4eecae739b097997e80eea8b3&amp;f=10063558&amp;site=13442&amp;p0=https%3A%2F%2Fbj.yzf.com.cn%2Findex_ms.html&amp;ct=4&amp;refer=&amp;loc=https%3A%2F%2Fbj.yzf.com.cn%2Findex_ms.html&amp;_d=1721821211876&amp;command=forceChat&amp;autoChat=1&amp;follow=1">
        </iframe>
      </div>
    </van-popup>
  </div>
</template>

<script>

export default {
  name: "projectService",
  data() {
    return {

    }
  },
  mounted() {


  },
  created() {

  },

  methods: {

    close() {

      this.$root.serviceDialog = false
   
    },
    open() {
      this.$root.serviceDialog = true
    }
  },
  computed: {
    serviceDialog:{
      get(){
             return this.$root.serviceDialog
        },
        set(v){
          this.$root.serviceDialog = v
        }
    }
   
  },
}
</script>
<style lang="scss" scoped>
.projectService {
  .doyoo_panel_mobile {
    width: 55px;
    height: 55px;
    background: url("../assets/images/mobile_online.png") no-repeat center center;
    position: fixed;
    top: 50%;
    right: 5px;
    z-index: 800;
  }

  .van-popup {
    box-shadow: -0 -3px 10px 0 #333;
  }

  .doyoo_f_work_wrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  #doyoo_f_head {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1;
  }

  .van-overlay {
    background: rgba(0, 0, 0, 0);
  }

}
</style>
